//开台详情页接口请求方法
import request from "@/request/request";


export function stockloss(data) {    //库存报损
  return request({
    url: "/clerk/goods/stockloss",
    method: "post",
    data: data,
  });
}
export function stocktaking(data) {    //库存盘点
  return request({
    url: "/clerk/goods/stocktaking",
    method: "post",
    data: data,
  });
}
export function stockout(data) {    //库存沽清
  return request({
    url: "/clerk/goods/stockout",
    method: "post",
    data: data,
  });
}
export function stockoutList(data) {    //沽清列表
  return request({
    url: "/clerk/goods/stockoutList",
    method: "post",
    data: data,
  });
}
export function limitedStockoutList(data) {    //限量沽清列表
  return request({
    url: "/clerk/goods/limitedStockoutList",
    method: "post",
    data: data,
  });
}
export function limitedStockout(data) {    //限量库存沽清
  return request({
    url: "/clerk/goods/limitedStockout",
    method: "post",
    data: data,
  });
}
export function clearLimitedStockout(data) {    //限量库存沽清
  return request({
    url: "/clerk/goods/clearLimitedStockout",
    method: "post",
    data: data,
  });
}
export function batchUpdateGoods(data) {    //批量修改商品
  return request({
    url: "/clerk/goods/batchUpdateGoods",
    method: "post",
    data: data,
  });
}
export function getPractice(data) {    //获取商品所有的忌口口味做法
  return request({
    url: "/store/setting/getPractice",
    method: "post",
    data: data,
  });
}
export function fastUpdateGoods(data) {    //快速修改商品
  return request({
    url: "/clerk/goods/fastUpdateGoods",
    method: "post",
    data: data,
  });
}


