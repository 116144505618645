<template>
  <div>
    <a-modal
      :visible="isShow"
      :title="name"
      @cancel="handleCancel"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >    
        <template slot="footer">
          <div style="text-align: center;">
            <a-button class="clearBtn" @click="handleCancel">取消</a-button>
            <a-button class="OkBtn" @click="handleOk">确定</a-button>
          </div>
        </template>
        <div class="stock">原库存:{{itemData&&itemData.stock_total}}</div>
         <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules" 
          >
            <a-form-model-item ref="num" label="报损数量" prop="num">
              <a-input   v-model="form.num"  @pressEnter="handleOk"/>
            </a-form-model-item>
          </a-form-model>
      <stocknum :parentNum="this.form.num" @hidden_num="updateNum"/>
    </a-modal>
  </div>
</template>
<script>
import stocknum from "@/components/keycode/stocknum";
export default {
  props: ["isShow","itemData" ,'name'],
  data() {
    return {
      labelCol: { span: 4},
      wrapperCol: { span:14  },
       form: {
         num:"",
      },
      rules: {
        num: [
          { required: true, message: '请填写报损数量', trigger: 'change' },
        ],
      }   
    };
  },
  watch:{
    itemData:{
      handler(){ 
        console.log(JSON.stringify(this.itemData))  
        // this.form.num=this.itemData.stock_total?this.itemData.stock_total.toString():"";
      },
      deep:true,
    }
  },
  mounted(){
  },
   updated(){
  
  },
  methods: {
    handleCancel() {
      this.form.num="";
      this.$emit('modal',true);
    },
    handleOk() {
      const _this = this;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const object={
              goods_name:_this.itemData && _this.itemData.goods_name,
              stock_total:_this.itemData && _this.itemData.stock_total,
              loss_num:_this.form.num,
              goods_id:_this.itemData && _this.itemData.goods_id,
          };
        this.form.num="";
        _this.$emit('number',object);
        _this.$emit('modal',true);
        } else {
          return false;
        }
      }); 
    },
     updateNum(num){
      this.form.num = num;
    },
  },
  components: {
    stocknum,
  },
};
</script>
 <style lang="less" scoped>


/deep/.ant-modal-footer {
  text-align: center;
}
/deep/.window{
  margin: 2vh 1vw 0vh 1vw;
}
.ant-input {
  width: 15vw;
}
/deep/.ant-form-item {
  display: flex;
}
.stock{
  font-size: 1.8vh;
  color:rgba(0, 0, 0, 0.85);
  margin-bottom: 1vh;
  margin-left:0.5vw;
  // border-bottom: 1px solid #e8e8e8;
}
/deep/.ant-form-item label {
  font-size: 1.8vh;
}
/deep/.ant-modal-body{
  padding-right: 10px;
  padding-top:10px;
}
/deep/.ant-modal-content{
  width:30vw;
  background:rgba(237, 238, 242, 1);
}
/deep/.ant-modal-header{
   background:rgba(237, 238, 242, 1);
}
</style>