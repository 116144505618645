<template>
  <div>
    <a-modal
      :visible="areShow"
      title="提交报损单"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <div style="text-align: center;">
          <a-button class="clearBtn" @click="handleCancel">取消</a-button>
          <a-button class="OkBtn" @click="handleOk">确定</a-button>
        </div>
      </template>
      <div class="checkbox">
        <a-checkbox @change="onChange">打印单据</a-checkbox>
      </div>
      <div class="myForm">
        <a-form-model 
          :label-col="{ span: 5 }" 
          :wrapper-col="{ span: 12 }"
          ref="ruleForm"
          :model="form"
          :rules="rules"
        >
          <a-form-model-item label="损坏原因"  ref="reason" prop="reason">
            <a-select
              placeholder="请选择报损原因"
              :dropdownMenuStyle="{ maxHeight: '50vh' }"
              v-model="form.reason"
            >
              <a-select-option v-for="(item, index) in items" :key="index" :value="item.name">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: ["areShow"],
  data() {
    return {
      isShow: false,
      items: [
        { name: "商品损坏" },
        { name: "当天剩余" },
        { name: "商品过期" },
        { name: "商品免单" },
        { name: "商品试吃" },
        { name: "包装损坏" },
        { name: "商品腐烂" },
        { name: "其他原因" },
      ],
      form: {
        reason:undefined,
      },
      rules: {
        reason: [
          { required: true, message: '请选择报损原因', trigger: 'change' },
        ],
      },
      print:0,//是否打印
    };
  },
  methods: {
    handleCancel() {
      this.$emit('changeStatue',true);
    },
    handleOk(e) {
      const _this=this;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
         this.$emit('choose',_this.form.reason,_this.print);
        } else {
          return false;
        }
      });
    },
    onChange(e){
      console.log(e.target.checked);
      if(e.target.checked){
        this.print=1
      }else{
        this.print=0
      }
    }
  },
};
</script>
 <style lang="less" scoped>
/deep/.ant-modal-content {
  width: 75vh;
}
/deep/.ant-btn {
  width: 17vh;
  height: 5vh;
  font-size: 1.8vh;
}
/deep/.ant-form-item-label {
  margin-left: 9vh;
}
.myForm {
  padding: 3vh 3vh 0 0;
}
.checkbox {
  text-align: center;
}
/deep/.ant-modal-footer {
  text-align: center;
  margin-top: 3vh;
}
</style>