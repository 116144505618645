<template>
  <div class="window">
        <ul class="ulJian">
          <li v-for="(item, index) in liDate" :key="index" @click="peopNum(item)">
            {{ item }}
          </li>
          <li><img :src="back" style="width: 31%" @click="peopNumDef()" /></li>
        </ul>
  </div>
  <!--
    父组件调用示例
    <keyNum @hidden_num="updateNum" :parentNum="this.form.num"></keyNum>
    -->
</template>

<script>

export default {
    data(){
        return{
            liDate: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "-", "0"],
            back: require("@/assets/backspace-solid.svg"),
            num:0,
        }
    },
    methods: {
        //开台数字按钮
        peopNum(e) {
            let str = this.parentNum.toString();
            let num = e.toString();
            if (num == "") {
                this.num = num;
            } else {
                str += num;
                this.num = str;                
            }
            this.reurnParent();
            console.log(this.num);
        },
        //开台数字删除按钮
        peopNumDef() {
            let str = this.parentNum.toString();
            let len = str.length;
            if(len>1){
                this.num = str.substring(0,(len-1));
            }else{
                this.num=''
            }
            this.reurnParent();
            console.log(len);
        },
        //取消后回传值 给父组件
        reurnParent() {
            this.$emit("hidden_num", this.num);
        },

        //提示框
        openNotification(title, content) {
        this.$notification.open({
            message: title,
            description: content,
            icon: <a-icon type="smile" style="color: #108ee9" />,
        });
        },
    },
    props:{
        parentNum:{
            type:String,
            default:()=>0
        },
    },
    watch:{
        parentNum:{
            handler(oldNum, newNum){
                this.num = oldNum;
            }
        }
    },
};
</script>

<style lang="less" scoped>


.ulJian {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 1vh 0 1vh 0;
  display: flex;
  flex-wrap: wrap;

  li {
    width: 32%;
    height: 6.2vh;
    font-weight: bold;
    text-align: center;
    line-height: 6.2vh;
    border-bottom: 1px solid #b5b5b6;
    border-right: 1px solid #b5b5b6;
    cursor: pointer;
    font-size: 2vh;
  }
  li:nth-of-type(3) {
    border-right: none;
  }
  li:nth-of-type(6) {
    border-right: none;
  }
  li:nth-of-type(9) {
    border-right: none;
  }
  li:nth-of-type(12) {
    border-bottom: none;
    border-right: none;
  }
  li:nth-of-type(10) {
    border-bottom: none;
  }
  li:nth-of-type(11) {
    border-bottom: none;
  }
  li:hover {
    background: #eb6617;
    color: #fff;
    border: #fff solid 1px;
  }

}
</style>
